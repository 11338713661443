import CommandPalette from './command-palette-element'
import {Item} from './item'
import {sendEvent} from '../hydro-analytics'

/**
 * Sends a hydro BrowserEvent with command palette meta data
 *
 * @param type: The type of the event, this is prepended to 'command_palette-TYPE' to create the event name
 * @item Item: The item that is acted on and for which we can send more details
 *
 */
export function sendTrackingEvent(type: string, item?: Item) {
  const commandPalette = document.querySelector<CommandPalette>('command-palette')!

  const meta = {
    command_palette_session_id: commandPalette.sessionId,
    command_palette_scope: commandPalette.query.scope.type,
    command_palette_mode: commandPalette.getMode(),
    command_palette_title: item?.group === 'commands' ? item.title : '',
    command_palette_position: item?.position,
    command_palette_score: item?.score,
    command_palette_group: item?.group,
    command_palette_item_type: item?.itemType
  }

  sendEvent(`command_palette_${type}`, meta)
}
