import {TemplateResult, html, render} from '@github/jtml'
import {attr, controller, target} from '@github/catalyst'
import CommandPalette from './command-palette-element'
import {Item} from './item'
import {copyText} from './copy'

@controller
export class CommandPaletteItemElement extends HTMLElement {
  @attr itemId: string
  @attr itemTitle: string
  @attr subtitle = ''
  @attr selected = false
  @attr score: number

  @target titleElement: HTMLElement
  @target iconElement: HTMLElement
  @target subtitleElement: HTMLElement
  @target hintText: HTMLElement
  @target persistentHint: HTMLElement
  @target containerElement: HTMLAnchorElement

  titleNodes: Node[] = []
  item: Item
  hint?: string
  href: string
  rendered = false
  newTabOpened = false

  containerDataTarget = 'command-palette-item.containerElement'
  containerClasses = 'mx-2 px-2 rounded-2 d-flex flex-items-start no-underline border-0'
  containerStyle = 'padding-top: 10px; padding-bottom: 10px; cursor: pointer;'

  renderOcticon(octiconSvg: string) {
    this.iconElement.innerHTML = octiconSvg
  }

  renderAvatar(src: string, alt: string) {
    render(this.item.getAvatarTemplate(src, alt), this.iconElement)
  }

  setItemAttributes(item: Item) {
    this.item = item
    this.itemId = item.id
    this.itemTitle = item.title
    this.hint = item.hint
    this.href = item.path

    if (item.subtitle) {
      this.subtitle = item.subtitle
    }
  }

  connectedCallback() {
    if (this.subtitle) {
      this.subtitleElement.removeAttribute('hidden')
    }

    // titleNodes can contain <strong> tags to emphasise any text present in the query
    // so we can replace the plain text title with these, if there are any
    if (this.titleNodes.length > 0) {
      this.titleElement.textContent = ''
      this.titleElement.append(...this.titleNodes)
    }

    this.containerElement.addEventListener('click', (event: Event) => {
      this.item.activate(this.commandPalette, event)
    })
  }

  get commandPalette() {
    return this.closest<CommandPalette>('command-palette')!
  }

  attributeChangedCallback(name: string, _old: string, newValue: string) {
    if (this.rendered) {
      if (name === 'data-selected') {
        this.setSelectionAppearance()
        this.item.select(this)
      } else if (name === 'data-item-title') {
        this.titleElement.textContent = newValue
      } else if (name === 'data-subtitle') {
        this.subtitleElement.textContent = newValue
      }
    }
  }

  setSelectionAppearance() {
    if (this.selected) {
      this.containerElement.classList.add('color-bg-subtle')
      this.hintText.hidden = false
    } else {
      this.containerElement.classList.remove('color-bg-subtle')
      this.hintText.hidden = true
    }
  }

  renderLinkContainer(content: TemplateResult) {
    return html`
      <a
        data-target="${this.containerDataTarget}"
        href="${this.href}"
        class="${this.containerClasses}"
        data-skip-pjax
        style="${this.containerStyle}"
      >
        ${content}
      </a>
    `
  }

  renderSpanContainer(content: TemplateResult) {
    return html`
      <span data-target="${this.containerDataTarget}" class="${this.containerClasses}" style="${this.containerStyle}">
        ${content}
      </span>
    `
  }

  renderElementContent(): TemplateResult {
    return html`
      <div
        data-target="command-palette-item.iconElement"
        class="mr-2 color-fg-muted d-flex flex-items-center"
        style="height: 24px;"
      ></div>

      <div class="flex-1 d-flex flex-column" style="line-height: 24px;">
        <span data-target="command-palette-item.titleElement" class="color-fg-default f5">${this.itemTitle}</span>
        <p data-target="command-palette-item.subtitleElement" class="color-fg-muted f6 mb-0" hidden>${this.subtitle}</p>
      </div>

      <div class="color-fg-muted f5" style="line-height: 20px;">
        <span class="hide-sm" data-target="command-palette-item.hintText" style="line-height: 24px;" hidden
          >${this.item.getHint()}</span
        >
        <span
          class="hide-sm"
          data-target="command-palette-item.persistentHint"
          style="line-height: 24px;"
          hidden
        ></span>
      </div>
    `
  }

  renderElement() {
    const content = this.renderElementContent()
    const itemTemplate = () => {
      if (this.href) {
        return this.renderLinkContainer(content)
      } else {
        return this.renderSpanContainer(content)
      }
    }

    render(itemTemplate(), this)
    this.rendered = true
  }

  // Behavior moved over from Item.ts

  // Link behavior

  activateLinkBehavior(_commandPalette: CommandPalette, event: Event, isPlatformMetaKey: boolean) {
    const link = this.containerElement

    if (isPlatformMetaKey && link instanceof HTMLAnchorElement) {
      this.newTabOpened = true
      this.openLinkInNewTab(link)
    } else {
      this.newTabOpened = false
      this.openLink(link)
    }
  }

  openLinkInNewTab(link: HTMLAnchorElement) {
    const previousTarget = link.getAttribute('target')
    link.setAttribute('target', '_blank')
    link.click()

    if (previousTarget) {
      link.setAttribute('target', previousTarget)
    } else {
      link.removeAttribute('target')
    }
  }

  openLink(link: HTMLAnchorElement) {
    link.click()
  }

  //Compy behavior

  /**
   * Copy given text to the clipboard and display a hint to the user.
   *
   * @param text to be copied
   * @param hintText to display to user (defaults to 'Copied!')
   */
  copyToClipboardAndAnnounce(text: string, hintText?: string) {
    copyText(text)

    const hint = this.hintText
    const previousHint = hint.textContent
    hint.classList.add('color-fg-success')
    hint.textContent = hintText ?? 'Copied!'
    setTimeout(() => {
      hint.classList.remove('color-fg-success')
      hint.textContent = previousHint
    }, 2000)
  }

  // Default hint behavior

  /**
   * Returns hint text to display on hover
   */
  getHint(): TemplateResult {
    if (this.hint) {
      return html`<span class="hide-sm">${this.hint}</span>`
    } else if (this.item.scope) {
      return html`<div class="hide-sm">
        <kbd class="hx_kbd">Enter</kbd>
        to jump to
        <kbd class="hx_kbd ml-1">Tab</kbd>
        to search
      </div>`
    } else {
      return html`<span class="hide-sm">Jump to</span>`
    }
  }
}
