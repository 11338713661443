import {TemplateResult, html} from '@github/jtml'
import {hasMatch, positions, score} from 'fzy.js'
import CommandPalette from './command-palette-element'
import {CommandPaletteItemElement} from './command-palette-item-element'
import {CommandPaletteItemGroupElement} from './command-palette-item-group-element'
import {ItemData as ItemAPI} from './command-palette-api'
import {Scope} from './command-palette-scope-element'
import {crc32} from '@allex/crc32'
import {sendTrackingEvent} from './tracking'

export interface ItemData extends ItemAPI {
  score: number
  action: Action
  icon?: ItemIcon
  scope?: Scope
}

interface ItemIcon {
  type: string
  id?: string
  url?: string
  alt?: string
}

export interface Action {
  type: string
  description?: string
  path?: string
}

export function item(itemClass: typeof Item): void {
  Item.register(itemClass)
}

export class Item implements ItemData {
  static itemClasses = {} as {[id: string]: typeof Item}

  static defaultData: ItemData = {
    title: '',
    score: 1,
    priority: 1,
    action: {
      type: '',
      path: ''
    },
    icon: {
      type: 'octicon',
      id: 'dash-color-fg-muted'
    },
    group: CommandPaletteItemGroupElement.defaultGroupId
  }

  static register(classObject: typeof Item) {
    this.itemClasses[classObject.itemType] = classObject
  }

  static get itemType() {
    return this.buildItemType(this.name)
  }

  static buildItemType(className: string) {
    return className
      .replace(/([A-Z]($|[a-z]))/g, '_$1')
      .replace(/(^_|_Item$)/g, '')
      .toLowerCase()
  }

  _id: string
  title: string
  priority: number
  score: number
  subtitle?: string
  typeahead?: string
  scope?: Scope
  icon?: ItemIcon
  hint?: string
  group: string
  position = ''
  newTabOpened = false
  match_fields?: string[] | undefined
  _action: Action

  // Memoization
  _element?: CommandPaletteItemElement

  static build(data: ItemData) {
    const itemClass = this.itemClasses[data.action.type]

    if (itemClass) {
      return new itemClass(data)
    } else {
      throw new Error(`No item handler for ${data.action.type}`)
    }
  }

  constructor(data: ItemData) {
    this.title = data.title
    this.priority = data.priority
    this.score = data.score
    this.subtitle = data.subtitle
    this.typeahead = data.typeahead
    this.scope = data.scope
    this.hint = data.hint
    this.icon = data.icon
    this.group = data.group ?? CommandPaletteItemGroupElement.defaultGroupId
    this.match_fields = data.match_fields
    this._action = data.action
  }

  get action() {
    return this._action
  }

  get element(): CommandPaletteItemElement {
    if (!this._element) {
      this._element = new CommandPaletteItemElement()
      this._element.setItemAttributes(this)
    }
    return this._element
  }

  getAvatarTemplate(src: string, alt: string) {
    return html`<img src="${src}" alt="${alt}" class="avatar avatar-1 avatar-small circle" />`
  }

  /**
   * Returns hint text to display on hover
   */
  getHint(): TemplateResult {
    return this.element.getHint()
  }

  /**
   * Build a string that uniquely identifies this item. By default, an item is
   * identified from its action type, group and title.
   */
  get key() {
    return `${this.action.type}/${this.title}/${this.group}`
  }

  get id() {
    if (!this._id) {
      this._id = crc32(this.key).toString()
    }

    return this._id
  }

  get path(): string {
    return this.action.path || ''
  }

  get itemType() {
    return Item.buildItemType(this.constructor.name)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  select(_: CommandPaletteItemElement) {
    // Default no-op
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deselect(_: CommandPaletteItemElement) {
    // Default no-op
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  activate(commandPalette: CommandPalette, event?: Event) {
    sendTrackingEvent(this.activateTrackingEventType, this)
  }

  // When created a new item subclass please create your own activateTrackingEventType
  // that sends a more specific tracking event type for your item if needed.
  get activateTrackingEventType() {
    return 'activate'
  }

  activateLinkBehavior(commandPalette: CommandPalette, event: Event, isPlatformMetaKey: boolean) {
    this.element.activateLinkBehavior(commandPalette, event, isPlatformMetaKey)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  copy(_commandPalette: CommandPalette) {
    sendTrackingEvent('copy', this)
  }

  render(selected: boolean, queryText?: string): CommandPaletteItemElement {
    this.element.renderElement()

    if (selected) {
      this.element.selected = true
    }

    if (queryText) {
      this.element.titleNodes = this.emphasizeTextMatchingQuery(this.title, queryText)
    }

    return this.element
  }

  /**
   * Takes a string and emphasizes the portion that matches. The match is case
   * insensitive and emphasizes any partial match.
   *
   * When there is no match, an array containing a single text node is returned.
   *
   * @param text
   * @param queryText
   * @returns list of nodes representing the text
   */
  emphasizeTextMatchingQuery(text: string, queryText: string): Node[] {
    if (!hasMatch(queryText, text)) {
      const textNodeWithEverything = document.createTextNode(text)
      return [textNodeWithEverything]
    }

    const nodes = [] as Node[]
    let lastPosition = 0
    for (const matchingPosition of positions(queryText, text)) {
      const notMatchingText = text.slice(lastPosition, matchingPosition)
      if (notMatchingText !== '') {
        const textNode = document.createTextNode(text.slice(lastPosition, matchingPosition))
        nodes.push(textNode)
      }

      lastPosition = matchingPosition + 1
      const mark = document.createElement('strong')
      mark.textContent = text[matchingPosition]
      nodes.push(mark)
    }

    const textNode = document.createTextNode(text.slice(lastPosition))
    nodes.push(textNode)

    return nodes
  }

  /**
   * Copy given text to the clipboard and display a hint to the user.
   *
   * @param text to be copied
   * @param hintText to display to user (defaults to 'Copied!')
   */
  copyToClipboardAndAnnounce(text: string, hintText?: string) {
    this.element.copyToClipboardAndAnnounce(text, hintText)
  }

  /**
   * Calculate a score for this item for a given query text.
   *
   * This calculates a score for values returned by `matchFields` and returns the highest score.
   *
   * @param queryText string to match against
   */
  calculateScore(queryText: string) {
    const scores = this.matchFields.map(field => this.calculateScoreForField({field, queryText}))
    return Math.max(...scores)
  }

  calculateScoreForField({field, queryText}: {field: string; queryText: string}) {
    if (hasMatch(queryText, field)) {
      return score(queryText, field)
    } else {
      return -Infinity
    }
  }

  /**
   * To change how this item is matched, set `match_fields` in result data.
   * If not present, item will match against `this.title`.
   *
   * @returns list of strings to match against query
   */
  get matchFields() {
    if (this.match_fields) {
      return this.match_fields
    } else {
      return [this.title]
    }
  }

  autocomplete(commandPalette: CommandPalette) {
    const input = commandPalette.commandPaletteInput
    const typeahead = this.typeahead
    if (typeahead !== undefined) {
      input.value = input.overlay + typeahead
    } else {
      input.value = input.overlay + this.title
    }
  }
}
